<template id="my_checkbox">
    <div class="checkbox">
        <input type="hidden" :name="value.name" v-model="value.check">
        <input 
            :id="value.name" 
            type="checkbox" 
            :checked="value.check"
            v-model="value.check"
            >
        <label :for="value.name">{{value.label}}</label>
    </div>
</template>

<script>
export default {
    props:{
        value: Object,
    }
}
</script>

<style lang="scss">

.checkbox {
    display: block;
}

.checkbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.checkbox label {
    position: relative;
    cursor: pointer;
}

.checkbox label:before {
    content:'';
    -webkit-appearance: none;
    background: #FFFFFF;
    border: 1px solid #CACEDA;
    border-radius: 4px;
    padding: 15px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 15px;
}

.checkbox input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 1px;
    left: 13px;
    width: 7px;
    height: 12px;
    border: solid #2974FF;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}


</style>